export const DocumentTypeConstants = {
  IDENTIFICATION_DOCS: [
    {
      key: 'identity-card',
      value: 'FRENCH_IDENTITY_CARD',
      maxFileCount: 5
    },
    {
      key: 'passport',
      value: 'FRENCH_PASSPORT',
      maxFileCount: 5
    },
    {
      key: 'permit',
      value: 'FRENCH_RESIDENCE_PERMIT',
      maxFileCount: 5
    },
    {
      key: 'drivers-license',
      value: 'DRIVERS_LICENSE'
    },
    {
      key: 'france-identite',
      value: 'FRANCE_IDENTITE'
    },
    {
      key: 'other',
      value: 'OTHER_IDENTIFICATION',
      maxFileCount: 5
    }
  ],

  GUARANTOR_IDENTIFICATION_DOCS: [
    {
      key: 'identity-card',
      value: 'FRENCH_IDENTITY_CARD',
      maxFileCount: 5
    },
    {
      key: 'passport',
      value: 'FRENCH_PASSPORT',
      maxFileCount: 5
    },
    {
      key: 'permit',
      value: 'FRENCH_RESIDENCE_PERMIT',
      maxFileCount: 5
    },
    {
      key: 'drivers-license',
      value: 'DRIVERS_LICENSE'
    },
    {
      key: 'france-identite',
      value: 'FRANCE_IDENTITE'
    },
    {
      key: 'other',
      value: 'OTHER_IDENTIFICATION',
      maxFileCount: 5
    }
  ],

  RESIDENCY_DOCS: [
    {
      key: 'tenant',
      value: 'TENANT',
      maxFileCount: 10
    },
    {
      key: 'owner',
      value: 'OWNER',
      maxFileCount: 10
    },
    {
      key: 'guest',
      value: 'GUEST',
      maxFileCount: 10
    },
    {
      key: 'guest-parents',
      value: 'GUEST_PARENTS',
      maxFileCount: 10
    },
    {
      key: 'guest-organism',
      value: 'GUEST_ORGANISM',
      maxFileCount: 10
    },
    {
      key: 'short-term-rental',
      value: 'SHORT_TERM_RENTAL',
      maxFileCount: 10
    },
    {
      key: 'other-residency',
      value: 'OTHER_RESIDENCY',
      maxFileCount: 0
    }
  ],

  GUARANTOR_RESIDENCY_DOCS: [
    {
      key: 'tenant',
      value: 'TENANT',
      maxFileCount: 10
    },
    {
      key: 'owner',
      value: 'OWNER',
      maxFileCount: 10
    },
    {
      key: 'guest',
      value: 'GUEST',
      maxFileCount: 10
    },
    {
      key: 'guest-organism',
      value: 'GUEST_ORGANISM',
      maxFileCount: 10
    },
    {
      key: 'short-term-rental',
      value: 'SHORT_TERM_RENTAL',
      maxFileCount: 10
    },
    {
      key: 'other-residency',
      value: 'OTHER_RESIDENCY',
      maxFileCount: 0
    }
  ],

  PROFESSIONAL_DOCS: [
    {
      key: 'cdi',
      value: 'CDI',
      maxFileCount: 20
    },
    {
      key: 'cdi-trial',
      value: 'CDI_TRIAL',
      maxFileCount: 20
    },
    {
      key: 'cdd',
      value: 'CDD',
      maxFileCount: 20
    },
    {
      key: 'alternation',
      value: 'ALTERNATION',
      maxFileCount: 20
    },
    {
      key: 'internship',
      value: 'INTERNSHIP',
      maxFileCount: 20
    },
    {
      key: 'student',
      value: 'STUDENT',
      maxFileCount: 20
    },
    {
      key: 'public',
      value: 'PUBLIC',
      maxFileCount: 20
    },
    {
      key: 'ctt',
      value: 'CTT',
      maxFileCount: 20
    },
    {
      key: 'retired',
      value: 'RETIRED',
      maxFileCount: 20
    },
    {
      key: 'unemployed',
      value: 'UNEMPLOYED',
      maxFileCount: 20
    },
    {
      key: 'independent',
      value: 'INDEPENDENT',
      maxFileCount: 20
    },
    {
      key: 'intermittent',
      value: 'INTERMITTENT',
      maxFileCount: 20
    },
    {
      key: 'artist',
      value: 'ARTIST',
      maxFileCount: 20
    },
    {
      key: 'stay-at-home-parent',
      value: 'STAY_AT_HOME_PARENT',
      maxFileCount: 20
    },
    {
      key: 'no-activity',
      value: 'NO_ACTIVITY',
      maxFileCount: 20
    },
    {
      key: 'other',
      value: 'OTHER',
      maxFileCount: 20
    }
  ],

  GUARANTOR_PROFESSIONAL_DOCS: [
    {
      key: 'cdi',
      value: 'CDI',
      maxFileCount: 20
    },
    {
      key: 'cdi-trial',
      value: 'CDI_TRIAL',
      maxFileCount: 20
    },
    {
      key: 'cdd',
      value: 'CDD',
      maxFileCount: 20
    },
    {
      key: 'alternation',
      value: 'ALTERNATION',
      maxFileCount: 20
    },
    {
      key: 'internship',
      value: 'INTERNSHIP',
      maxFileCount: 20
    },
    {
      key: 'student',
      value: 'STUDENT',
      maxFileCount: 20
    },
    {
      key: 'public',
      value: 'PUBLIC',
      maxFileCount: 20
    },
    {
      key: 'ctt',
      value: 'CTT',
      maxFileCount: 20
    },
    {
      key: 'retired',
      value: 'RETIRED',
      maxFileCount: 20
    },
    {
      key: 'unemployed',
      value: 'UNEMPLOYED',
      maxFileCount: 20
    },
    {
      key: 'independent',
      value: 'INDEPENDENT',
      maxFileCount: 20
    },
    {
      key: 'intermittent',
      value: 'INTERMITTENT',
      maxFileCount: 20
    },
    {
      key: 'artist',
      value: 'ARTIST',
      maxFileCount: 20
    },
    {
      key: 'stay-at-home-parent',
      value: 'STAY_AT_HOME_PARENT',
      maxFileCount: 20
    },
    {
      key: 'no-activity',
      value: 'NO_ACTIVITY',
      maxFileCount: 20
    },
    {
      key: 'other',
      value: 'OTHER',
      maxFileCount: 20
    }
  ],

  FINANCIAL_DOCS: [
    {
      key: 'salary',
      value: 'SALARY',
      maxFileCount: 10
    },
    {
      key: 'social-service',
      value: 'SOCIAL_SERVICE',
      maxFileCount: 10
    },
    {
      key: 'pension',
      value: 'PENSION',
      maxFileCount: 10
    },
    {
      key: 'rent',
      value: 'RENT',
      maxFileCount: 10
    },
    {
      key: 'scholarship',
      value: 'SCHOLARSHIP',
      maxFileCount: 10
    },
    {
      key: 'no-income',
      value: 'NO_INCOME',
      maxFileCount: 0
    }
  ],

  GUARANTOR_FINANCIAL_DOCS: [
    {
      key: 'guarantor_salary',
      value: 'SALARY',
      maxFileCount: 10
    },
    {
      key: 'social-service',
      value: 'SOCIAL_SERVICE',
      maxFileCount: 10
    },
    {
      key: 'pension',
      value: 'PENSION',
      maxFileCount: 10
    },
    {
      key: 'rent',
      value: 'RENT',
      maxFileCount: 10
    },
    {
      key: 'scholarship',
      value: 'SCHOLARSHIP',
      maxFileCount: 10
    }
  ],

  TAX_DOCS: [
    {
      key: 'my-name',
      value: 'MY_NAME',
      maxFileCount: 5
    },
    {
      key: 'my-parents',
      value: 'MY_PARENTS',
      maxFileCount: 0
    },
    {
      key: 'less-than-year',
      value: 'LESS_THAN_YEAR',
      maxFileCount: 0
    },
    {
      key: 'other-tax',
      value: 'OTHER_TAX',
      maxFileCount: 0
    }
  ],

  GUARANTOR_TAX_DOCS: [
    {
      key: 'my-name',
      value: 'MY_NAME',
      maxFileCount: 5
    },
    {
      key: 'less-than-year',
      value: 'LESS_THAN_YEAR',
      maxFileCount: 0
    },
    {
      key: 'other-tax',
      value: 'OTHER_TAX',
      maxFileCount: 0
    }
  ],

  GUARANTOR_ORGANISM_DOCS: [
    {
      key: 'visale',
      value: 'VISALE',
      maxFileCount: 5
    },
    {
      key: 'other-guarantee',
      value: 'OTHER_GUARANTEE',
      maxFileCount: 5
    }
  ],

  REPRESENTATIVE_IDENTIFICATION: [
    {
      key: 'identity-card',
      value: 'FRENCH_IDENTITY_CARD'
    },
    {
      key: 'passport',
      value: 'FRENCH_PASSPORT'
    },
    {
      key: 'permit',
      value: 'FRENCH_RESIDENCE_PERMIT'
    },
    {
      key: 'drivers-license',
      value: 'DRIVERS_LICENSE'
    },
    {
      key: 'france-identite',
      value: 'FRANCE_IDENTITE'
    },
    {
      key: 'other',
      value: 'OTHER_IDENTIFICATION'
    }
  ]
}
